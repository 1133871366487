import { Component } from '@angular/core';

@Component({
    selector: 'app-v3-base',
    templateUrl: './v3-base.component.html',
    styleUrls: ['./v3-base.component.scss']
})
export class V3BaseComponent {

}
