<div class="contact-wrapper">
    <div class="contact-content">
        <div class="contact-content-container">

            <div class="contact-title-section" data-aos="fade-up">
                <h1>Get in Touch</h1>
            </div>

            <div class="contact-body">

                <div class="left-container" data-aos="fade-right">
                    <div class="information">
                        <p>
                            Do you have a question or a proposal? Or would you simply like to say hello?
                        </p>
                        <p>
                            Feel free to reach out to discuss opportunities or simply to chat. I would love to hear from
                            you!
                        </p>
                    </div>
                    <div class="socials">

                        <div class="social instagram" title="Instagram" (click)="openLink(socials_config.instagram)">
                            <button class="social-btn">
                                <span class="svgContainer">
                                    <svg fill="white" class="svgIcon" viewBox="0 0 448 512"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                        </path>
                                    </svg>
                                </span>
                                <span class="background"></span>
                            </button>
                        </div>

                        <div class="social github" title="Github" (click)="openLink(socials_config.github)">
                            <button class="social-btn">
                                <span class="svgContainer">
                                    <svg class="cf-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="-2.5 0 19 19">
                                        <path
                                            d="M9.464 17.178a4.506 4.506 0 0 1-2.013.317 4.29 4.29 0 0 1-2.007-.317.746.746 0 0 1-.277-.587c0-.22-.008-.798-.012-1.567-2.564.557-3.105-1.236-3.105-1.236a2.44 2.44 0 0 0-1.024-1.348c-.836-.572.063-.56.063-.56a1.937 1.937 0 0 1 1.412.95 1.962 1.962 0 0 0 2.682.765 1.971 1.971 0 0 1 .586-1.233c-2.046-.232-4.198-1.023-4.198-4.554a3.566 3.566 0 0 1 .948-2.474 3.313 3.313 0 0 1 .091-2.438s.773-.248 2.534.945a8.727 8.727 0 0 1 4.615 0c1.76-1.193 2.532-.945 2.532-.945a3.31 3.31 0 0 1 .092 2.438 3.562 3.562 0 0 1 .947 2.474c0 3.54-2.155 4.32-4.208 4.548a2.195 2.195 0 0 1 .625 1.706c0 1.232-.011 2.227-.011 2.529a.694.694 0 0 1-.272.587z">
                                        </path>
                                    </svg>
                                </span>
                                <span class="background"></span>
                            </button>
                        </div>

                        <div class="social twitter" title="Twitter" (click)="openLink(socials_config.twitter)">
                            <button class="social-btn">
                                <span class="svgContainer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="twitter">
                                        <path
                                            d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429">
                                        </path>
                                    </svg>
                                </span>
                                <span class="background"></span>
                            </button>
                        </div>

                        <div class="social linkedin" title="Linkedin" (click)="openLink(socials_config.linkedin)">
                            <button class="social-btn">
                                <span class="svgContainer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                        data-supported-dps="24x24" fill="currentColor" class="mercado-match"
                                        focusable="false" class="linkedin">
                                        <path
                                            d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z">
                                        </path>
                                    </svg>
                                </span>
                                <span class="background"></span>
                            </button>
                        </div>

                        <div class="social gmail" title="Gmail" (click)="openLink(socials_config.gmail)">
                            <button class="social-btn">
                                <span class="svgContainer">
                                    <svg height="2500" width="2500" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 49.4 512 399.4200000000001" class="gmail">
                                        <g fill="none" fill-rule="evenodd">
                                            <g fill-rule="nonzero">
                                                <path
                                                    d="M34.91 448.818h81.454V251L0 163.727V413.91c0 19.287 15.622 34.91 34.91 34.91z"
                                                    fill="#4285f4" />
                                                <path
                                                    d="M395.636 448.818h81.455c19.287 0 34.909-15.622 34.909-34.909V163.727L395.636 251z"
                                                    fill="#34a853" />
                                                <path
                                                    d="M395.636 99.727V251L512 163.727v-46.545c0-43.142-49.25-67.782-83.782-41.891z"
                                                    fill="#fbbc04" />
                                            </g>
                                            <path d="M116.364 251V99.727L256 204.455 395.636 99.727V251L256 355.727z"
                                                fill="#ea4335" />
                                            <path
                                                d="M0 117.182v46.545L116.364 251V99.727L83.782 75.291C49.25 49.4 0 74.04 0 117.18z"
                                                fill="#c5221f" fill-rule="nonzero" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="background"></span>
                            </button>
                        </div>

                    </div>
                </div>

                <div class="right-container" data-aos="fade-left">
                    <div class="form-container">
                        <form class="contact-form">
                            <p class="form-heading">Contact</p>
                            <div class="field name">
                                <input class='form-input' type="text" required id="contact-name"
                                    (change)="onchange('contact-name')">
                                <label for="contact-name">Name</label>
                            </div>
                            <span class="error" *ngIf="nameChanged">Name is required!</span>
                            <div class="field email">
                                <input class='form-input' type="text" required id="contact-email"
                                    (change)="onchange('contact-email')">
                                <label for="contact-email">Email</label>
                            </div>
                            <span class="error" *ngIf="emailChanged">Email is required!</span>
                            <div class="field message">
                                <textarea class='form-input textarea' type="text" required rows="3" placeholder=""
                                    id="contact-textarea" (change)="onchange('contact-textarea')"></textarea>
                                <label for="contact-textarea">Message</label>
                            </div>
                            <span class="error" *ngIf="textareaChanged">Message is required!</span>
                            <a class="send" (click)="sendMail()">
                                SEND
                            </a>
                            <div class="badge" title="Mail me" (click)="openLink(socials_config.gmail)">

                                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64">
                                    <path id="Mail" d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6
	h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017
	L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003
	L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14
	C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z
	 M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401
	c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981
	l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z" />
                                </svg>
                                <a class="email">yash.aggarwal.8618&#65312;gmail.com</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>